@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');

:root{
  --corPrincipal: red;
  --corSecundaria: yellow;
  --corTexto: black;
  --corTextoBotao : white;
  --secaoProdutoCor: white;
  --secaoClienteCor : #e5e5e5;
  --secaoVideoCor : white; 
  --secaoRodapeCor: black;
  --secaoRodapeTextoCor: white;
}

body{
  font-family: 'Open Sans', sans-serif;
}

ul {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
}

ul > li:before {
  display: inline-block;
  content: "-";
  width: 1em;
  margin-left: -1em;
}

.lading-page-container {
  height: 100vh;  

}

.mb-1 {
  margin-bottom: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mr-1{
  margin-right: 1rem;
}

.pr-1{
  margin-right: 1rem;
}

.d-flex{
  display: flex;
}

.row {
  flex-direction: row;
  flex-wrap: wrap;
}
.column{
  flex-direction: column;
  flex-wrap: wrap;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33%;
}

.col-5 {
  width: 41.66%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.3333%;
}

.col-8 {
  width: 66.66%;
}

.col-9 {
  width: 83.33%;
}

.align-center{
  align-items: center;
}

.justify-start{
  justify-content: start;
}

.justify-end{
  justify-content: flex-end;
}

.justify-center{
  justify-content: center;
}

.link-loja{
  color: var(--secaoRodapeTextoCor);
}

.button-call-action {  
  display: inline-block !important;
  font-weight: 500;
  color: var(--corTextoBotao);
  background-color: var(--corPrincipal);
  text-align: center;  
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 1rem 1.5rem;
  line-height: 1.5;
  border-radius: .3rem;
  font-size: 1.2rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  outline:none;
}

.button-call-action:hover {
  opacity: 0.9;
}

.header {
  height: 80px;
  width: 100%;  
  margin-bottom: 0.5rem;
  position: fixed;
  z-index: 100;  
  
}

.header.normal {
  background-color: transparent;
  width: 100%;
}

.header.smaller {
  box-shadow: 0px 2px 5px rgba(0,0,0,0.20); 
  background-color: var(--corSecundaria);
  
}

.header-content {
    
  height: 100%; 
  width: 100%;
  display: flex;
  flex-direction: row; 
  
}

.nav {  
  padding-left: 2rem;
  padding-right: 2rem;
  
  height: 100%; 
  width: 100%;
  overflow: hidden;  
  display: flex;
  flex-direction: row;
  
}

.nav a {  
  color: var(--corPrincipal);
  text-align: center;  
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 500;  
}

.nav a:hover {
  
}

.nav-logo-container {  
  
  display: flex;
  align-items: center;
  
}

.nav-logo-container a{
  display: flex;
  align-items: center;
  
  
}

.nav-logo img{  
  height: 50px;
  
  margin: 0;
  padding: 0;
}



.text-header-telefone {
  padding: 0.5rem;
  font-size: 1.5rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--corTexto);
} 

.text-header-cidade {
  padding: 0.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--corTexto);
}

.produto-container {
  display: flex;
  width: 100%;
  min-height: 450px;    
  
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center;
  padding-top: 80px;
  background-color: var(--secaoProdutoCor);  
  
}

.produto-content {  
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 2rem;      
}

.produto-img {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;     
  
}

.produto-img img{   
  max-height: 70vh;
  max-width: 55vw;    
  margin-right: 1rem;
}

.produto-desc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  
}

.titulo-produto {
  font-size: 4rem;
  line-height: 3.5rem;
  margin: 0px;
  color: var(--corTexto);

}

.produto-text {
  font-size: 1.2rem;
  
  color: var(--corTexto);
}

.sessao-customizada-container{
  padding: 2rem;
}

.clientes-container {
  background-color:  var(--secaoClienteCor) !important;
  height: 18vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center !important;
  overflow-y: none;
  overflow-x: scroll;  
}

.clientes-container::-webkit-scrollbar {
  height: 0 !important; 
  width: 0 !important 
}


.clientes-container > img {
  width: auto;
  height: 72px;
  padding: 1rem;
}

.video-container {  
  background-color: var(--secaoVideoCor);
  justify-content: center;  
  align-items: center;
  width: 100%;  
}

.video-wrapper {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;    
}

.video-wrapper iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.footer-container {  
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--secaoRodapeCor);
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.footer-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-telefone{
  font-size: 1.5rem !important;
  margin-bottom: 1rem;
}

.footer-quote {
  margin-top: 2rem;
  font-size: 0.75rem;
  color: var(--secaoRodapeTextoCor);  
  opacity: 0.7;
}

.bg-colr{
  background-color: pink;
}

/* Pagina 404 */

.pagina-404 {
  background-color: #FCFCFC;
  width: 100%;
  height: 100vh;
  margin:0;  
  overflow: hidden;
  display: flex; 
  flex-direction: column; 
  justify-content: flex-start;
  align-items: center;
  color: #54045c;
  text-align: center;
}

.titulo-404{  
  font-size: 3rem;  
  color: #C0D8E0
}

.pagina-404-logo{
  width: 30%;
  margin-bottom: 2rem;
}

.link-404{
  color: #54045c; 
  font-size: 1.5rem;
}

@media only screen and (max-width: 768px) {
 
  .col-sm-3 {
    width: 25%;
  }
  
  .col-sm-4 {
    width: 33.33%;
  }
  
  .col-sm-5 {
    width: 41.66%;
  }
  
  .col-sm-6 {
    width: 50%;
  }
  
  .col-sm-7 {
    width: 58.3333%;
  }
  
  .col-sm-8 {
    width: 66.66%;
  }
  
  .col-sm-9 {
    width: 83.33%;
  }

  .col-sm-12 {
    width: 100%;
  }

  .header-content {
    width: 100%;
    height: 100%;
    margin: 0;     
    padding: 0;
  }

  .nav{
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .text-header-cidade {
    display: none;
  }
  .produto-content {    
    margin: 0rem;      
  }
  
  .produto-img{        
    justify-content: center;
    align-items: center;  
  } 
  .produto-img img{
    margin-right: 0;
    max-height: 100vh;
    max-width: 95vw;    
  }

  .titulo-produto{
    font-size: 6rem;
    line-height: 5rem;
  }

  .produto-desc {  
    padding: 1rem;  
    justify-content: center;
    align-items: flex-start;  
  }

  .produto-desc > .button-call-action{
    display: none !important;
  }

  .video-container {    
    width: 100%;
    padding: 0rem;   
  }

  .pagina-404-logo{
    width: 50%;
    margin-bottom: 2rem;
  }

  .clientes-container {
    justify-content: flex-start;
  }
 
}